<script setup lang="ts">
import { RequestReadCampers } from '@yescapa-dev/ysc-api-js/modern'
import { useRentalAPI } from '~/composables/useRentalAPI'
import type { PageBodyElementVehicleListing } from '~~/types/api/page/PageBodyElement'

interface Props {
  content: PageBodyElementVehicleListing['value']
  id: string
  reverse?: boolean
}

const props = defineProps<Props>()

const { api } = useRentalAPI()
const getRentalSearchHref = () => {
  const url = useRentalBaseURL()
  url.pathname = '/s'
  if (props.content.latitude) {
    url.searchParams.set('latitude', props.content.latitude.toString())
  }
  if (props.content.longitude) {
    url.searchParams.set('longitude', props.content.longitude.toString())
  }
  if (props.content.vehicle_type) {
    url.searchParams.set('vehicle_type', props.content.vehicle_type)
  }
  return url.href
}
const searchUrl = getRentalSearchHref()

const { data, status } = await useAsyncData(`app-post-block-vehicles-listing-${props.id}`, async () => {
  const { results } = await api.send(new RequestReadCampers({ query: {
    page_size: 3,
    types: props.content.vehicle_type,
    latitude: props.content.latitude,
    longitude: props.content.longitude,
    radius: 100000,
  } }))

  return results
}, {
  lazy: true,
})

const { isLoading } = useIsLoading(status)

const getRentalCamperUrl = (id: number) => {
  const url = useRentalBaseURL()
  url.pathname = `/campers/${id}`

  return url.href
}
</script>

<template>
  <div
    class="mx-auto max-w-screen-lg space-y-8 px-4 py-12 md:py-20"
    :class="{ 'bg-gray-50': props.reverse }"
  >
    <div class="md:flex md:justify-between md:items-center md:gap-x-8">
      <h3 class="text-xl font-semibold lg:text-2xl">
        {{ props.content.title }}
      </h3>
      <div class="hidden md:mt-4 md:block lg:mt-0 lg:shrink-0">
        <NuxtLink
          :to="searchUrl"
          target="_blank"
          external
          class="btn btn-secondary"
        >
          {{ $t('links.all_vehicule') }}
        </NuxtLink>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="text-center"
    >
      <YscLoader class="h-8 w-8" />
    </div>
    <div
      v-else
      class="no-scrollbar flex overflow-x-auto"
    >
      <div class="flex flex-nowrap gap-6 lg:grid lg:w-full lg:grid-cols-3">
        <YscCamperCard
          v-for="camper in data"
          :key="camper.id"
          :camper-url="getRentalCamperUrl(camper.id)"
          :camper="camper"
          :vehicle-type="useVehicleType({ type: camper.vehicle_type })?? ''"
          :currency="{ currency: camper.price_per_day_currency, currencyDisplay: 'symbol', style: 'currency' }"
          :show-wishlist="false"
          show-price
          show-link
          show-reviews
          class="min-w-[300px] auto-rows-min"
        >
          <template #slideshow>
            <YscSlideShow
              :items="camper.picture_set.slice(0, 8)"
              aspect="aspect-[16/10]"
              class="rounded"
            >
              <template #default="{ activeItem }">
                <twic-img
                  :key="activeItem.id"
                  :src="`/rental${activeItem.path}`"
                  placeholder="maincolor"
                  pre-transform="refit=auto(20p)"
                  ratio="16/10"
                />
              </template>
            </YscSlideShow>
          </template>
        </YscCamperCard>
      </div>
    </div>

    <div class="md:hidden">
      <NuxtLink
        :to="searchUrl"
        target="_blank"
        external
        class="btn btn-small btn-secondary"
      >
        {{ $t('links.all_vehicule') }}
      </NuxtLink>
    </div>
  </div>
</template>
